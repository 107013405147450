.table-success {
    --bs-table-bg: #e3f2fd;
    border-color: #dee2e6;
}

.table-success-g {
    --bs-table-bg: #c4e4fa;
    border-color: #dee2e6;
}

.bg-success {
    background-color: rgba(var(--bs-primary-rgb),
            var(--bs-bg-opacity)) !important;
}

.btn-success {
    background-color: #2196f3;
    border-color: #2196f3;
}



.btn-success:hover,
.btn-success:focus,
.btn-success:active {
    background-color: #42a5f5;
    border-color: #42a5f5;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    box-shadow: none;
}

.btn-outline-success {
    color: #2196f3;
    border-color: #2196f3;
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success:hover,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success:hover,
.btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: none;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: none;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}