// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
//@import "~bootstrap/scss/bootstrap";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "custom";

// Font-awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";


// Custom
.swal2-popup {
  @apply shadow shadow-lg dark:bg-gray-700 #{!important};
}

.swal2-title {
  @apply dark:text-white #{!important};
}

.swal2-confirm {
  @apply text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg
  text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800
  #{!important};
}

.swal2-cancel {
  @apply text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg
  text-sm px-4 py-2 dark:bg-gray-500 dark:hover:bg-gray-600 focus:outline-none dark:focus:ring-gray-700
  #{!important};
}